@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Heebo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-datepicker {
  background-color: #1A1827 !important;
  border-color: #4B5563 !important;
  font-family: inherit;
}

.react-datepicker__header {
  background-color: #1A1827 !important;
  border-bottom-color: #4B5563 !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day {
  color: white !important;
}

.react-datepicker__day:hover {
  background-color: #4C1D95 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background-color: #7C3AED !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #6D28D9 !important;
}

.react-datepicker__input-container input {
  direction: rtl;
}